import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import PoemHaelfteDesLebens from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/poem-haelfte-des-lebens"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/lesen-und-einpraegen/aufgabe" />
      }
      navigation={navigation}
    >
      <Seo title="Lesen & Einprägen" />
      <Stack>
        <Heading as="h2" level={2}>
          Lesen & Einprägen
        </Heading>
        <TaskSidebar
          main={
            <PaperStack>
              <PoemHaelfteDesLebens showTitle />
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Lies dir das Gedicht ein- oder auch mehrmals durch und versuche,
              es dir einzuprägen.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
